<template>
  <div class="home-page d-flex min-vh-100">
    <CContainer fluid>
      <CRow>
        <CCol>
          <CHeader fixed with-subheader light class="header-nav">
            <CHeaderBrand class="mx-auto site_logo" to="/">
              <!-- <img
                src="@/assets/icons/logoLR_yoko.svg"
                height="48"
                class="img-fluid"
                alt="Liberty"
              /> -->
            </CHeaderBrand>
          </CHeader>
        </CCol>
      </CRow>

      <CRow class="mt-5 pt-5 justify-content-center">
        <CCol sm="11" lg="9">
          <CRow class="mx-5 p-5">
            <CCol>
              <CRow>
                <CCol class="px-0 pb-3">
                  <h2>404 ERROR</h2>
                </CCol>
              </CRow>
              <CRow>
                <CCol class="px-0 pb-3">
                  <p>
                    このたびは「リバティーリゾート会員システム」のホームページをご覧頂きまして、ありがとうございます。<br />
                    申し訳ございませんが、お探しのページはアクセスができない状況にあるか、<br />
                    移動もしくは削除された可能性があります。<br />
                    下記リンクよりトップページへお戻りください。
                  </p>
                  <CButton
                    color="dark"
                    size="lg"
                    class="px-5 my-5"
                    @click="$router.push('home')"
                    ><i class="icon cil-arrow-left"></i> ホーム
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow class="footer fixed-bottom">
        <CCol>
          <CRow class="justify-content-center pt-4">
            <CCol class="app_section"> </CCol>
          </CRow>
          <CRow>
            <CCol>
              <TheFooter />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CContainer>

  </div>
</template>

<script>
// import Vue from 'vue'
import TheFooter from "@/containers/TheFooter";

export default {
  components: {
    TheFooter,
  },
  name: "NotFound",
};
</script>